.custom-input-height.form-select-sm {
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
}
.custom-input-height.form-control-sm {
  padding-top: 0.65rem !important;
  padding-bottom: 0.65rem !important;
}
.form-select-sm::placeholder {
  color: #a4afc4 !important;
  font-weight: normal !important;
}
.form-control-sm::placeholder {
  color: #a4afc4 !important;
  font-weight: normal !important;
}
.custom-input-height::placeholder {
  color: #a4afc4 !important;
  font-weight: normal !important;
}
.no-caret::after {
  display: none;
}
.form-control-sm {
  border: 1px solid #e4e6ef;
}
.input-group-text {
  border: 1px solid #e4e6ef;
}
.form-select-sm {
  border: 1px solid #e4e6ef;
}

#drag.table {
  width: 100%;
  border-collapse: collapse;
}

#drag .table th,
.table td {
  /* border: 1px solid #ddd; */
  padding: 8px;
}

#drag .table-row {
  cursor: move;
  transition: background-color 0.2s ease;
}

#drag .table-row:hover {
  background-color: #f0f0f0;
}
.no-caret::after {
  display: none;
}
.dropdown-menu-left-1 {
  right: 10px !important; /* Aligns the menu to the right edge of the toggle */
  left: auto !important; /* Disables the default left positioning */
}
.dropdown-menu-left {
  right: 0px !important; /* Aligns the menu to the right edge of the toggle */
  left: auto !important; /* Disables the default left positioning */
}

.dropdown-menu-left.show {
  display: block;
}

#sumtable.table tbody tr:last-child td:last-child {
    border-bottom: 2px solid black !important;
}


.grid-item {
    background: #fff;
    border: 1px dashed #ddd;
    border-radius: 8px;
    padding: 20px;
    text-align: start;
    padding: 10px;
    background-color: white;
    display: flex;
    cursor: pointer;
}



