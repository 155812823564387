.wave-container {
  position: relative;
  overflow: hidden;
}

.wave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px; /* Adjust height as needed */
  background: rgba(247, 247, 247, 0.13); /* Wave color */
  border-radius: 50%;
  animation: wave-animation 15s infinite linear;
}

@keyframes wave-animation {
  0% {
    transform: translateX(-100%) translateY(0);
  }
  40% {
    transform: translateX(100%) translateY(-40px);
  }
  100% {
    transform: translateX(-100%) translateY(0);
  }
}
.vertical-separator {
    width: 1.5px; /* Adjust width as needed */
    /* height: 100%;  */
    background-color: black; /* Black color */
    margin: 0 10px; /* Space around it */
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
   .vertical-separator{
    display: none	!important;
   }
   .t1{
    font-size: 10px !important;
   }
}