.custom-search-radius.form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.custom-search-btn-radius.btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.no-caret::after {
  display: none;
}
.light-hover:hover{
  background-color: #eff2f5 !important;
}
.shadow-custom{
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px !important;
}
/* .zoom:hover {
  transform: scale(1.2); 
} */