.custom-search-radius.form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.custom-search-btn-radius.btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.no-caret::after {
  display: none;
}