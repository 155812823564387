@media (max-width: 680px) {
  .custom-hide-class {
    display: none !important;
  }
  .custom-show-class {
    display: block !important;
  }
}
@media (min-width: 681px) {
  .custom-show-class {
    display: none !important;
  }
}
