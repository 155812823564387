.wave-container {
  position: relative;
  overflow: hidden;
}

.wave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px; /* Adjust height as needed */
  background: rgba(247, 247, 247, 0.13); /* Wave color */
  border-radius: 50%;
  animation: wave-animation 25s infinite linear;
}

@keyframes wave-animation {
  0% {
    transform: translateX(100%) translateY(0);
  }
  50% {
    transform: translateX(-100%) translateY(-50px);
  }
  100% {
    transform: translateX(100%) translateY(0);
  }
}
/* live dot  */
.blinking-dot {
  width: 10px;
  height: 10px;
  background-color: #09c018;
  border-radius: 50%;
  animation: blink 2s infinite, wave 2s infinite;
}

.blinking-dot-small {
  width: 5px;
  height: 5px;
  background-color: #09c018;
  border-radius: 50%;
  animation: blink 2s infinite, wave 2s infinite;
}



@keyframes blink {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.8;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

@keyframes wave {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
}
.tableFixHead {
  overflow: auto;
  height: 250px;
}

.tableFixHead thead th {
  position: sticky !important;
  top: 0 !important;
  background-color: white;
  z-index: 1;
}
