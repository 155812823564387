//
// Dark Sidebar(used by Dark Sidebar layout only)
//

[data-kt-app-layout='dark-sidebar'] {
  .app-sidebar {
    background-color: #ffffff !important;
    border-right: 0 !important;

    .hover-scroll-overlay-y {
      @include scrollbar-color(
        $app-sidebar-dark-scrollbar-color,
        $app-sidebar-dark-scrollbar-color-hover
      );
    }

    // .app-sidebar {
    //     box-shadow: 0 0 3px 20px rgba(0, 0, 0, 0.12), 0 2px 3px 0 rgba(0, 0, 0, 0.22);
    // }

    .btn-custom {
      @include button-custom-variant(
        $color: #b5b5c3,
        $icon-color: #b5b5c3,
        $border-color: null,
        $bg-color: rgba(63, 66, 84, 0.35),
        $color-active: #b5b5c3,
        $icon-color-active: null,
        $border-color-active: null,
        $bg-color-active: rgba(63, 66, 84, 0.35)
      );
    }

    .menu {
      .menu-item {
        .menu-heading {
          color: $app-sidebar-dark-menu-heading-color !important;
        }

        @include menu-link-default-state(
          $title-color: #99a1b7,
          $icon-color: #99a1b7,
          $bullet-color: #787887,
          $arrow-color: #a3a242,
          $bg-color: null
        );

        @include menu-link-hover-state(
          $title-color: #a3a242,
          $icon-color: #a3a242,
          $bullet-color: var(--kt-primary-inverse),
          $arrow-color: var(--kt-primary-inverse),
          $bg-color: null
        );

        @include menu-link-here-state(
          $title-color: var(--kt-primary-inverse),
          $icon-color: var(--kt-primary-inverse),
          $bullet-color: var(--kt-primary-inverse),
          $arrow-color: var(--kt-primary-inverse),
          $bg-color: null
        );

        @include menu-link-show-state(
          $title-color: var(--kt-primary-inverse),
          $icon-color: var(--kt-primary-inverse),
          $bullet-color: var(--kt-primary-inverse),
          $arrow-color: var(--kt-primary-inverse),
          $bg-color: null
        );

        @include menu-link-active-state(
          $title-color: #a3a242,
          $icon-color: #a3a242,
          $bullet-color: #787887,
          $arrow-color: #a3a242,
          $bg-color: #ffffff
        );
      }
    }
    //  .menu-link-active-state {
    //      box-shadow: var(--kt-box-shadow) !important;

    //     }
  }
}
