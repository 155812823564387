.form-check-label {
  color: #A1A5B7 !important; 
}

.form-check-input:checked ~ .form-check-label {
  color: #333333 !important; 
}


.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--kt-form-check-input-checked-bg-color) !important;
  border-color: var(--kt-form-check-input-checked-border-color) !important;
}