.form-control-sm {
   border: 1px solid #e4e6ef;
}

.custom-input-height.form-select-sm {
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
  border: 1px solid #e4e6ef;
}
.custom-input-height.form-control-sm {
  padding-top: 0.65rem !important;
  padding-bottom: 0.65rem !important;
  border: 1px solid #e4e6ef;
}
.form-select-sm::placeholder {
  color: #a4afc4 !important;
  font-weight: normal !important;
}
.form-control-sm::placeholder {
  color: #a4afc4 !important;
  font-weight: normal !important;
}
.custom-input-height::placeholder {
  color: #a4afc4 !important;
  font-weight: normal !important;
}
.no-caret::after {
  display: none;
}
